import { createContext } from 'react'
import { Action, State } from '../interface/uiInterface'

const initialState: State = {
  isPopupOpened: false,
  isLoading: false,
  isLoadingSpinner: false,
  isStickyPopupOpened: false,
  viewExtendedChecks: false,
}
const UiContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: initialState,
  dispatch: () => null,
})
export default UiContext
