import { createContext } from 'react'
import {
  IBrowserCheckResultsData,
  IResultsContext,
  IResultsData,
} from './../interface/resultsInterface'

export const InitialContext = {
  resultsData: {
    check_ts: '',
    ip_check_id: '',
    domain: '',
    findings: null,
    target_ip: '',
  },
  setResultsData: (newResultsData: IResultsData) => {
    InitialContext.resultsData = newResultsData
  },
  browserCheckResultsData: {
    browser_check_id: '',
    browser_name: '',
    check_ts: '',
    current_version: '',
    is_up_to_date: false,
    latest_version: '',
  },
  setBrowserCheckResultsData: (
    newBrowserCheckResultsData: IBrowserCheckResultsData
  ) => {
    InitialContext.browserCheckResultsData = newBrowserCheckResultsData
  },
  ipAddress: '',
  setIpAddress: (ip: string) => {
    InitialContext.ipAddress = ip
  },
}

export const ResultsContext = createContext<IResultsContext>(InitialContext)
export default ResultsContext
