import { State, Action } from '../interface/uiInterface'

export const UiReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'POPUPTOGGLE':
      return {
        ...state,
        isPopupOpened: action.payload,
      }
    case 'LOADINGBAR':
      return {
        ...state,
        isLoading: action.payload,
      }
    case 'LOADINGSPINNER':
      return {
        ...state,
        isLoadingSpinner: action.payload,
      }
    case 'STICKYPOPUPTOGGLE':
      return {
        ...state,
        isStickyPopupOpened: action.payload,
      }
    case 'VIEWEXTENDEDCHECKS':
      return {
        ...state,
        viewExtendedChecks: action.payload,
      }
    default:
      return state
  }
}

export default UiReducer
