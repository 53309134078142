import React, { FC } from 'react'
import { Logo } from '../../atoms/Logo'
import { footerContactLinks } from '../../../_content/footerContactLinks'
import { footerAboutLinks } from '../../../_content/footerAboutLinks'
import { footerAdviceGuidanceLinks } from '../../../_content/footerAdviceGuidanceLinks'

export const Footer: FC = () => {
  const logEvent = (title: string) => {
    gtag('event', 'click', {
      event_category: 'FooterLink',
      event_label: title,
    })
  }

  return (
    <footer data-cy="footer" className="govuk-footer " role="contentinfo">
      <div className="govuk-width-container ">
        <div className="govuk-grid-row ">
          <div className="govuk-footer__navigation">
            <div className="govuk-footer__section govuk-grid-column-one-quarter">
              <div className="govuk-footer__meta">
                <div className="govuk-footer__meta-item govuk-footer__meta-item--grow">
                  <a
                    className="govuk-footer__link footer-copyright-logo logo__link"
                    target="_blank"
                    href="https://ncsc.gov.uk"
                    rel="noreferrer"
                  >
                    <Logo
                      width="218"
                      id="ncsc_logo"
                      fill="#fff"
                      className="ncsc-logo"
                      ariaTitle="NCSC footer logo"
                    />
                    <Logo
                      width="218"
                      id="ncsc_logo_black"
                      fill="#000"
                      className="ncsc-logo-black"
                      ariaTitle="NCSC footer logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="govuk-footer__section govuk-grid-column-three-quarters">
              <div className="govuk-footer__meta-item">
                <h2 className="govuk-footer__heading">
                  {footerAdviceGuidanceLinks.heading}
                </h2>
                <ul className="govuk-footer__list govuk-footer__list-advice-links">
                  {footerAdviceGuidanceLinks.items.map((item, key) => (
                    <li key={key} className="govuk-footer__list-item">
                      <a
                        className="govuk-footer__link"
                        target="_blank"
                        data-cy={item.testid}
                        href={item.url}
                        rel="noreferrer"
                        onClick={() => {
                          if (typeof item.title === 'string') {
                            logEvent(item.title)
                          }
                        }}
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="govuk-footer__meta-item">
                <h2 className="govuk-footer__heading">
                  {footerContactLinks.heading}
                </h2>
                <ul className="govuk-footer__list govuk-footer__list-contact">
                  {footerContactLinks.items.map((item, key) => (
                    <li key={key} className="govuk-footer__list-item">
                      <a
                        className="govuk-footer__link"
                        target="_blank"
                        data-cy={item.testid}
                        href={item.url}
                        rel="noreferrer"
                        onClick={() => {
                          if (typeof item.title === 'string') {
                            logEvent(item.title)
                          }
                        }}
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="govuk-footer__meta-item">
                <h2 className="govuk-footer__heading">
                  {footerAboutLinks.heading}
                </h2>
                <ul className="govuk-footer__list govuk-footer__list-about ">
                  {footerAboutLinks.items.map((item, key) => (
                    <li key={key} className="govuk-footer__list-item">
                      <a
                        className="govuk-footer__link"
                        target="_blank"
                        data-cy={item.testid}
                        href={item.url}
                        rel="noreferrer"
                        onClick={() => {
                          if (typeof item.title === 'string') {
                            logEvent(item.title)
                          }
                        }}
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
