import { IFooterLinksContent } from '../shared/interface/footer.interface'
export const footerAboutLinks: IFooterLinksContent = {
  heading: 'About this website',
  items: [
    {
      id: 1,
      title: 'Privacy notice',
      url: 'https://www.ncsc.gov.uk/section/about-this-website/privacy-statement',
      testid: 'privacy-notice',
    },
    {
      id: 2,
      title: 'Terms & Conditions',
      url: 'https://www.ncsc.gov.uk/section/about-this-website/terms-and-conditions',
      testid: 'terms-and-condition',
    },
    {
      id: 3,
      title: 'Accessibility',
      url: 'https://www.ncsc.gov.uk/section/about-this-website/accessibility',
      testid: 'accessibility',
    },
    {
      id: 4,
      title: 'Cookie Policy',
      url: 'https://www.ncsc.gov.uk/section/about-this-website/cookies',
      testid: 'cookie-policy',
    },
  ],
}
